export default class Form {
    constructor() {
        this.bind()
    }

    bind() {
        ;[].forEach.call(document.querySelectorAll('.form__input'), (input) => {
            input.setAttribute('value', input.value)
            input.addEventListener('input', () => {
                input.setAttribute('value', input.value)
            })
        })
        ;[].forEach.call(
            document.querySelectorAll('.icon-eye-cross'),
            (btn) => {
                btn.addEventListener('click', () => {
                    const container = btn.closest('div')
                    btn.classList.toggle('active')
                    if (container) {
                        const input = container.querySelector('input')
                        if (input) {
                            const type = input.getAttribute('type')

                            input.setAttribute(
                                'type',
                                type === 'password' ? 'text' : 'password'
                            )
                        }
                    }
                })
            }
        )
    }
}
