export default class Accordion {
    constructor() {
        const emptyArr = []
        const triggers = document.querySelectorAll('.js-accordion')

        emptyArr.forEach.call(triggers, (trigger) => {
            trigger.addEventListener('click', () => {
                console.log('click')
                const arrow = trigger.querySelector('[data-arrow]')
                const bodySelector = trigger.getAttribute('data-target')
                const body = document.querySelector(bodySelector)
                const open = trigger.getAttribute('data-open')

                if (open === 'true') {
                    if (arrow) arrow.classList.remove('open')
                    if (body) body.classList.remove('open')
                    trigger.setAttribute('data-open', 'false')
                } else {
                    if (arrow) arrow.classList.add('open')
                    if (body) body.classList.add('open')
                    trigger.setAttribute('data-open', 'true')
                }
            })
        })
    }
}
