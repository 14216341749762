const BLOCK_CLASS = 'mobile-header'

export default class MobileNavigation {
    constructor() {
        const menubar = document.getElementById('mobile-nav')
        const inner = document.getElementById('mobile-nav-inner')
        const triggers = document.querySelectorAll('.js-mobile-nav')
        const emptyArr = []

        emptyArr.forEach.call(triggers, (trigger) => {
            trigger.addEventListener('click', (e) => {
                e.preventDefault()
                menubar.classList.toggle(`${BLOCK_CLASS}--open`)
            })
        })

        menubar.addEventListener('click', (e) => {
            menubar.classList.remove(`${BLOCK_CLASS}--open`)
        })

        inner.addEventListener('click', (e) => {
            e.stopPropagation()
        })
    }
}
