export default class Filters {
    constructor() {
        const menubar = document.getElementById('filters')
        const inner = document.getElementById('filters-inner')
        const triggers = document.querySelectorAll('.js-filters')
        const emptyArr = []

        emptyArr.forEach.call(triggers, (trigger) => {
            trigger.addEventListener('click', (e) => {
                e.preventDefault()
                menubar.classList.toggle('open')
            })
        })

        if (menubar) {
            menubar.addEventListener('click', (e) => {
                menubar.classList.remove('open')
            })
        }

        if (inner) {
            inner.addEventListener('click', (e) => {
                e.stopPropagation()
            })
        }
    }
}
