import Masonry from 'masonry-layout'
import Swiper, { Navigation, Pagination } from 'swiper'
import Accordion from './libs/accordion'
import Auth from './libs/auth'
import Filters from './libs/filters'
import Form from './libs/form'
import MobileNavigation from './libs/mobileNavigation'
import Modal from './libs/modal'

window.addEventListener('load', () => {
    new MobileNavigation()
    new Filters()
    new Accordion()
    new Modal()
    new Form()
    new Auth()

    const msnryEl = document.querySelector('.js-grid')
    if (msnryEl) {
        const msnry = new Masonry(msnryEl, {
            itemSelector: '.js-grid-item',
            columnWidth: '.grid-sizer',
            percentPosition: true,
        })
    }

    const swiper = new Swiper('.swiper', {
        modules: [Navigation, Pagination],
        loop: true,
        pagination: {
            el: '.swiper-pagination',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    })
})
