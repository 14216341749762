export default class Modal {
    constructor() {
        document.body.addEventListener('click', (e) => {
            const trigger = e.target.closest('.js-modal')
            if (trigger) {
                this.handleModal(e, trigger)
            }
        })

        document.body.addEventListener('click', (e) => {
            const trigger = e.target.closest('.js-modal-close')
            if (trigger) {
                this.handleModalClose(e, trigger)
            }
        })

        document.body.addEventListener('click', (e) => {
            if (e.target.classList.contains('modal')) {
                this.closeModal(e.target)
            }
        })
    }

    handleModal(e, trigger) {
        e.preventDefault()

        const target = trigger.getAttribute('data-target')
        if (target) {
            const modal = document.getElementById(target)

            if (modal) {
                modal.classList.add('open')
            }
        }
    }

    handleModalClose(e, trigger) {
        e.preventDefault()

        const modal = trigger.closest('.modal')
        if (modal) {
            this.closeModal(modal)
        }
    }

    closeModal(modal) {
        modal.classList.remove('open')
    }
}
