export default class Auth {
    constructor() {
        const emptyArr = []
        const triggers = document.querySelectorAll('.js-auth-tab')
        const bodies = document.querySelectorAll('.js-auth-tab-body')

        emptyArr.forEach.call(triggers, (trigger) => {
            trigger.addEventListener('click', () => {
                if (!trigger.classList.contains('active')) {
                    // clear actives
                    ;[].forEach.call(triggers, (item) =>
                        item.classList.remove('active')
                    )
                    ;[].forEach.call(bodies, (item) =>
                        item.classList.remove('active')
                    )

                    trigger.classList.add('active')
                    const bodyEl = document.getElementById(
                        trigger.getAttribute('data-target')
                    )

                    if (bodyEl) {
                        bodyEl.classList.add('active')
                    }
                }
            })
        })
    }
}
